$modernizr-checks: csspointerevents, backgroundsize, csstransitions, cssanimations, csstransforms, bgsizecover, boxshadow, boxsizing, nthchild, flexwrap, csscalc, flexbox, opacity, rect, rgba;

@each $check in $modernizr-checks {
    .hide-#{$check} {
        @include supports($check) {
            display: none;
        }
    }
    
    .hide-no-#{$check} {
        @include rejects($check) {
            display: none;
        }
    }
}

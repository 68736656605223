$tail-size: 48px !default;
$tail-size-md: 32px !default;
$tail-size-sm: 24px !default;

@include tweak(tail) {
    &:not(.#{escape-symbols($csstyle-option-symbol)}no-margin) {
        margin-bottom: $tail-size;
    }
    
    &:before {
        content: '';
        @include absolute((right: $tail-size, bottom: -$tail-size));
        @include size($tail-size, $tail-size);
        
        @if ($debug) {
            background: rgba(#0F0, .5);
        }
    }
    
    &:after {
        content: '';
        @include absolute((top: 100%, right: $tail-size));
        @include size(0, 0);
        border-top-width: $tail-size;
        border-top-style: solid;
        border-left: $tail-size solid rgba(#000, 0);
        z-index: -1;
        pointer-events: none;
    }
    
    @include option(medium) {
        &:not(.#{escape-symbols($csstyle-option-symbol)}no-margin) {
            margin-bottom: $tail-size-md;
        }
        
        &:before {
            right: $tail-size-md;
            bottom: -$tail-size-md;
            @include size($tail-size-md, $tail-size-md);
        }
        
        &:after {
            right: $tail-size-md;
            border-top-width: $tail-size-md;
            border-left-width: $tail-size-md;
        }
    }
    
    @include option(small) {
        &:not(.#{escape-symbols($csstyle-option-symbol)}no-margin) {
            margin-bottom: $tail-size-sm;
        }
        
        &:before {
            right: $tail-size-sm;
            bottom: -$tail-size-sm;
            @include size($tail-size-sm, $tail-size-sm);
        }
        
        &:after {
            right: $tail-size-sm;
            border-top-width: $tail-size-sm;
            border-left-width: $tail-size-sm;
        }
    }
}

@mixin bg-style($color, $hex) {
    @include part($color) {
        background-color: $hex;
        color: color-contrast($hex);
        
        @if ($hex == $accent-color) {
            &::selection, *::selection {
                background-color: rgba($primary-color, .8);
                color: color-contrast(nth($primary-color, 1));
            }
        }
    }
}

@include tweak(bg) {
    @include bg-style(secondary-main, $grey);
    @include bg-style(accent, $accent-color);
    @include bg-style(primary, $primary-color);
    @include bg-style(secondary, $secondary-color);
    @include bg-style(inverse, $inverse-main-color);
}

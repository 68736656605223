#container > footer {
    background: $inverse-main-color;
    color: $inverse-text-color;
    z-index: 1;
    
    @include component(smallprint) {
        display: block;
        margin: 48px 0;
        @include policy(margin);
    }
    
    @include component(credits) {
        background: $credits-color;
        color: $faded-text-color;
        @include font-size(14px);
        
        a {
            color: desaturate($accent-color, 30%);
            
            &:hover {
                color: desaturate($accent-color, 50%);
            }
        }
    }
}
